import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import {Container, makeStyles} from "@material-ui/core";
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { Grid} from "@material-ui/core";

import IconButton from '@material-ui/core/IconButton';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const useStyles = makeStyles(() => ({
  randomposts: {
    color: "dimgray",
    textAlign: 'center',
    marginTop: "15vh",
    marginBottom: "15vh",
    paddingBottom: "3vh",
    backgroundColor: "ghostwhite",
  },
  image_tilte: {
    position: "absolute",
    top: "40%", 
    left: "10%",
    right: "10%",
    color: "white"
  }
}))


const RandomPosts = props => {
  const randomSelect = (array, num) => {
    let newArray = []

    while (newArray.length < num && array.length > 0) {
      const rand = Math.floor(Math.random() * array.length)
      newArray.push(array[rand])
      array.splice(rand, 1)
    }

    return newArray
  }

  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(
        sort: { order: DESC, fields: publishDate }
      ) {
        nodes {
          title
          slug
          id
          publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
          publishDate
          eyecatch {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const baseposts = data.allContentfulBlogPost.nodes.filter(
    node => node.id !== props.id
  )
  const randomposts = baseposts.slice(0, props.a_number)//randomSelect(baseposts, props.a_number)
  const classes = useStyles();

  const nextprecFontSize = props.breakpoints?.sm ? 16 : props.breakpoints.md ? 20 : 24
  return (
    <div className={classes.randomposts} >
      <Container fix>
        <div style={{paddingTop: "5vh", paddingBottom: "3vh", fontSize: props.breakpoints?.sm ? 20 : props.breakpoints.md ? 24 : 28}}>最近のニュース</div>
        <Grid container spacing={3}>
          {randomposts.map(node => {
            return (
              <Grid item xs={12} sm={6}>
                <div 
                  data-sal="slide-up"
                  data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
                  data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
                  data-sal-easing="ease" // 
                  >
                  <article className="post" key={node.id} style={{position: "relative"}}>
                    <Link to={`/blog/post/${node.slug}/`}>
                        <Img
                          fluid={node.eyecatch.fluid}
                          alt={node.eyecatch.description}
                          style={{ height: "100%" }}
                          loading="eager"
                          durationFadeIn={100}
                        />
                      <div className={classes.image_tilte} style={{fontSize: props.breakpoints?.sm ? 16 : props.breakpoints.md ? 20 : 24}}>{node.title}</div>
                    </Link>
                    <time dateTime={node.publishDate} style={{fontSize: props.breakpoints?.sm ? 10 : props.breakpoints.md ? 14 : 18}}>
                      <FontAwesomeIcon icon={faClock} />
                      {node.publishDateJP}
                    </time>
                  </article>
                </div>
              </Grid>
            )
          })}
        </Grid>
        <div style={{paddingTop: "3vh"}}>
          <Link to={`/blog`} rel="prev" aria-label="もっとみる" style={{textDecoration: "none",color: "gray",fontSize: nextprecFontSize}}>
            もっとみる
            <IconButton aria-label="もっとみる">
              <FontAwesomeIcon icon={faChevronRight} />
            </IconButton>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default RandomPosts
