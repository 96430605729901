import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "../components/layout"
import RandomPosts from "../components/randomposts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faFolderOpen } from "@fortawesome/free-regular-svg-icons"
import {
  faChevronLeft,
  faChevronRight,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons"
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

//import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { makeStyles } from '@material-ui/core/styles';
import { Container,Paper } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Seo from "../components/seo"

const useStyles = makeStyles(() => ({
  phrase: {
    color: "gray",
    textAlign: 'center',
    paddingTop: 150,
    marginLeft: 30,
    marginBottom: 50,
    marginRight: 30,
  },
  system: {
    backgroundColor: "ghostwhite",
    marginTop: 150,
    paddingBottom: 150,

  },
  postbody: {
    marginTop: "5vh",

  },
  postlink: {
    marginTop: "10vh",
    color: "#477294",
    fontSize: "14px",
    textAlign: "center",
    paddingBottom: "5vh",
  },
  cat: {
    paddingTop: "3vh",
    paddingBottom: "3vh"
  }
  
}))

const options= (breakpoints) => ({
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <div style={{paddingTop: "5vh", paddingBottom: "3vh", fontSize: breakpoints?.sm ? 22 : breakpoints.md ? 28 : 32}}>
        <DoubleArrowIcon />
        {children}
      </div>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p style={{paddingLeft: "3vw", paddingRight: "3vw", fontSize: breakpoints?.sm ? 16 : breakpoints.md ? 20 : 24}}>
        {children}
      </p>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <div style={{textAlign: "center"}}>
        { node.data.target.gatsbyImageData && 
        <GatsbyImage
          image={ getImage(node.data.target) }
          alt={ node.data.target.title }
          style= {{maxWidth: "50vw"}}
        />}
        {node.data.target.file.contentType ===  "video/mp4" && 
          <video autoPlay loop muted playsInline controls style={{ width:"50%" }}>
          <source src={node.data.target.file.url} />
        </video>}
      </div>
    ),
  },
})

const BlogpostTemp = ({ data, pageContext, location }) => {
  const breakpoints = useBreakpoint();
  const classes = useStyles();

  const keywordFontSize = breakpoints?.sm ? 12 : breakpoints.md ? 16 : 20
  const nextprecFontSize = breakpoints?.sm ? 16 : breakpoints.md ? 20 : 24

  return(
  <Layout location={location} breakpoints = {breakpoints}>
    <Seo
      title={data.contentfulBlogPost.title}
      description={data.contentfulBlogPost.description}
      pagepath={location.pathname}
      image={`https:${data.contentfulBlogPost.eyecatch.file.url}`}
      pageimgw={data.contentfulBlogPost.eyecatch.file.details.image.width}
      pageimgh={data.contentfulBlogPost.eyecatch.file.details.image.height}
    />
    <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "40vh", zIndex: -1,backgroundColor: "gainsboro"}}>
      <Img
        fluid={data.contentfulBlogPost.eyecatch.fluid}
        alt={data.contentfulBlogPost.eyecatch.description}
        loading="eager"
        durationFadeIn={100}
        style={{height: "inherit"}}/>
    </div>
    <div className={classes.phrase} style = {{color: "white", paddingBottom: 150,fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
      {data.contentfulBlogPost.title}
    </div>
    <div style={{backgroundColor: "floralwhite", paddingTop: "10vh", fontSize: breakpoints?.sm ? 12: breakpoints.md ? 16 : 20}}>
      <Container fix>
      <Paper style = {{padding: "5vw"}}>
        <article className="content">
          <div className="container">
            <div style={{textAlign: "right",fontSize: keywordFontSize}}>
              <time dateTime={data.contentfulBlogPost.publishDate}>
                <FontAwesomeIcon icon={faClock} />
                {data.contentfulBlogPost.publishDateJP}
              </time>
            </div>
              
            <div className={classes.postbody}>
              {renderRichText(data.contentfulBlogPost.content, options(breakpoints))}
            </div>

            <div className={classes.cat}>
                <FontAwesomeIcon icon={faFolderOpen} />
                  {data.contentfulBlogPost.category.map((cat,index) => (
                    <span className={cat.categorySlug} key={cat.id} style={{fontSize: keywordFontSize}}>
                      {index>0 ? "、":"" }
                      <Link to={`/cat/${cat.categorySlug}/`}>{cat.category}</Link>
                    </span>
                  ))}
              </div>

            <div className={classes.postlink}>
              {pageContext.previous && (
                <Tooltip title={pageContext.previous.title}>
                  <Link to={`/blog/post/${pageContext.previous.slug}/`} rel="prev" style={{textDecoration: "none",fontSize: nextprecFontSize, float: "left"}}>
                  <IconButton aria-label={pageContext.previous.title}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </IconButton>
                    新しいの記事へ
                  </Link>
                </Tooltip>
                )}
              {pageContext.next && (
                <Tooltip title={pageContext.next.title}>
                  <Link to={`/blog/post/${pageContext.next.slug}/`} rel="prev" style={{textDecoration: "none",fontSize: nextprecFontSize, float: "right"}}>
                    過去の記事へ
                    <IconButton aria-label={pageContext.next.title}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
            </div>

            
          </div>
        </article>
      </Paper>
      </Container>
      <RandomPosts a_number={5} id={pageContext.id} breakpoints={breakpoints}/>
    </div>
  </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
      publishDate
      category {
        category
        categorySlug
        id
      }
      description
      eyecatch {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            gatsbyImageData
            file {
              url
              contentType
            }
          }
        }
      }
    }
  }
`

export default BlogpostTemp
